import { Paginator, Platform, SortDirection } from '@app/classes';
import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import {
    ApproveButtonColumn,
    DateColumn,
    HoursColumn,
    HourTypeColumn,
    NameColumn,
    NoteColumn,
    ProjectColumn,
    StatusColumn,
} from './columns';
import { PayrollIncomeType } from '@time-tracking/interfaces/settings.interface';
import { Column } from '@app/modules/table/classes/column.class';

const deleteOption: MenuColumnItem = {
    label: 'time-tracking.delete',
    event: 'delete',
};

const editOption: MenuColumnItem = {
    label: 'time-tracking.edit',
    event: 'edit',
};

export class TimesheetsIndexTable extends Table {
    showMenuColumnOptions = true;
    sortDirection = SortDirection.DESC;
    links = true;

    constructor(
        protected auth: AuthService,
        private hasPayrollIntegration: boolean,
        private payrollIncomeTypes: PayrollIncomeType[] = []
    ) {
        super(auth);
        this.boot();
    }

    getLink(timesheetEntry: TimesheetEntry): (string | number)[] {
        // only admins can edit approved timesheets
        if (this.auth.cant(Platform.timeTracking.permission.view) && timesheetEntry.isApproved) {
            return [];
        }

        return [timesheetEntry.id, 'edit'];
    }

    setColumns(): void {
        const columns: Array<Column> = [new NameColumn(), new DateColumn(), new ProjectColumn(), new NoteColumn()];

        if (this.hasPayrollIntegration) {
            columns.push(new HourTypeColumn(this.payrollIncomeTypes));
        }

        columns.push(...[new HoursColumn(), new StatusColumn(), new ApproveButtonColumn()]);

        this.columns = columns;
    }

    getMenuColumnOptionsForRow(timesheetEntry: TimesheetEntry): MenuColumnItem[] {
        if (this.auth.can(Platform.timeTracking.permission.view) || !timesheetEntry.isApproved) {
            return [editOption, deleteOption];
        }

        return [];
    }

    setDataSource(): void {
        this.paginator = new Paginator(TimesheetEntry.where('supervised', true).with(['project', 'employee']));
    }
}
