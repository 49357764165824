import { ModelMixin } from '@app/models/core/base-generic.model';
import { Employee } from '@app/models/employee/employee.model';
import { Project } from '@app/models/time-tracking/project.model';

export class TimesheetEntry extends ModelMixin<TimesheetEntry>() {
    protected static _type = 'timeTracking.timesheetEntries';
    protected static _resource = 'timeTracking/timesheets';
    protected static _version = 'v2';
    protected static _serializeAttributes = [
        'note',
        'minutes',
        'workedOn',
        'projectId',
        'employeeId',
        'incomeType',
        'incomeTypeId',
        'salaryId',
    ];

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    set employeeId(value: number) {
        this._attributes['employeeId'] = value;
    }

    get incomeType(): string | null {
        return this._attributes['incomeType'];
    }

    set incomeType(val: string | null) {
        this._attributes['incomeType'] = val;
    }

    get incomeTypeId(): number | null {
        return this._attributes['incomeTypeId'];
    }

    set incomeTypeId(val: number | null) {
        this._attributes['incomeTypeId'] = val;
    }

    get salaryName(): string | null {
        return this._attributes['salaryName'];
    }

    get salaryId(): number | null {
        return this._attributes['salaryId'];
    }

    set salaryId(val: number | null) {
        this._attributes['salaryId'] = val;
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    set employee(employee: Employee | null) {
        this.setOne('employee', employee, 'employeeId');
    }

    get projectId(): number | null {
        return this._attributes['projectId'];
    }

    set projectId(value: number | null) {
        this._attributes['projectId'] = value;
    }

    get project(): Project | null {
        return this.hasOne(Project, 'project');
    }

    set project(project: Project | null) {
        this.setOne('project', project, 'projectId');
    }

    get note(): string {
        return this._attributes['note'];
    }

    set note(value: string) {
        this._attributes['note'] = value;
    }

    get minutes(): number {
        return this._attributes['minutes'];
    }

    set minutes(value: number) {
        this._attributes['minutes'] = value;
    }

    get workedOn(): string {
        return this._attributes['workedOn'];
    }

    set workedOn(value: string) {
        this._attributes['workedOn'] = value;
    }

    get isApproved(): boolean {
        return Boolean(this.approvedAt);
    }

    get approvedAt(): Date | null {
        return this._attributes['approvedAt'];
    }
}
