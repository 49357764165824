import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { TimesheetEntryData } from '@time-tracking/interfaces/timesheet-entry-assignment.interface';
import { TimeTrackingResources } from '../time-tracking.resource';

@Injectable({
    providedIn: 'root',
})
export class TimesheetEntryService {
    constructor(private http: HttpClient) {}

    /**
     * Create many timesheet entries with a single API call.
     */
    async createBatch(timesheetEntries: TimesheetEntry[]): Promise<void> {
        const payload = {
            data: {
                type: 'timeTracking.timesheetEntries',
                attributes: {
                    timesheets: this.createEntryData(timesheetEntries),
                },
            },
        };

        await this.http.post(TimeTrackingResources.TimesheetEntryBatch, payload).toPromise();
    }

    /**
     * approve approves a single timesheet entry
     */
    async approve(timesheetEntry: TimesheetEntry): Promise<void> {
        const url = TimeTrackingResources.TimesheetEntryApprove.replace(
            ':timesheetEntryId',
            timesheetEntry.id.toString()
        );
        await this.http.post(url, null).toPromise();
    }

    /**
     * approveAll approves all pending timesheet entries the user can access
     */
    async approveAll(searchPayload?: string): Promise<void> {
        let url = TimeTrackingResources.TimesheetEntryApproveAll;

        if (searchPayload) {
            url = `${url}?${searchPayload}` as TimeTrackingResources;
        }

        await this.http.post(url, null).toPromise();
    }

    async minutesWorkedOnDay(employeeId: number, date: string): Promise<number> {
        const url = `${TimeTrackingResources.MinutesWorkedOnDay}?employeeId=${employeeId}&date=${date}`;
        const result = await this.http.get<{ data: { attributes: { minutes: number } } }>(url).toPromise();
        return result.data.attributes.minutes;
    }

    private createEntryData(timesheetEntries: TimesheetEntry[]): TimesheetEntryData[] {
        return timesheetEntries.map((timesheetEntry) => ({
            projectId: timesheetEntry.projectId,
            employeeId: timesheetEntry.employeeId,
            workedOn: timesheetEntry.workedOn,
            minutes: timesheetEntry.minutes,
            note: timesheetEntry.note,
            incomeType: timesheetEntry.incomeType ?? null,
            incomeTypeId: timesheetEntry.incomeTypeId ?? null,
            salaryId: timesheetEntry.salaryId ?? null,
        }));
    }
}
